.base,
.callUs {
  position: absolute;
}
.base {
  height: 84%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 16%;
  left: 0;
}
.callUs {
  top: calc(50% - 25.85px);
  left: 31.37%;
  font-size: 14.62px;
  letter-spacing: 1px;
  line-height: 19.5px;
  text-transform: uppercase;
  font-weight: 600;
}
.pathIcon {
  position: absolute;
  height: 83.33%;
  width: 79.17%;
  top: 8.33%;
  right: 12.5%;
  bottom: 8.33%;
  left: 8.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.icon,
.iconCall {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.icon {
  height: 16.81%;
  width: 13.68%;
  top: 14.13%;
  right: 43.16%;
  bottom: 69.06%;
  left: 43.16%;
}
.chiamaciAlNumero {
  margin: 0;
}
.callUsToContainer {
  position: absolute;
  width: 91.17%;
  top: calc(50% + 4.78px);
  left: 4.41%;
  line-height: 17.23px;
  color: var(--color-white);
  display: inline-block;
  height: 52px;
}
.div {
  position: absolute;
  top: calc(50% + 72.22px);
  left: 24.89%;
  line-height: 16.57px;
  font-weight: 600;
}
.item {
  position: relative;
  width: 219.37px;
  height: 178.45px;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-poppins);
}
