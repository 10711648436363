.specific_page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #222737, #37425e);
}

.watermark {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.instructions {
  font-size: 0.875rem;
  border-radius: 0.5rem;
  background: #f2f2f2;
  color: #555555;
  padding: 0.5rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.5rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: #52c41a;
  margin-left: 0.5rem;
}

.invalid {
  color: #f5222d;
  margin-left: 0.5rem;
}

.errmsg {
  background-color: #ffe6e6;
  color: #ff4d4f;
  font-weight: bold;
  padding: 1rem;
  margin-bottom: 1rem;
}

.successmsg {
  background-color: #ffe6e6;
  color: #52c41a;
  font-weight: bold;
  padding: 1rem;
  margin-bottom: 1rem;
}

.line {
  display: inline-block;
  margin-left: 10px;
}

.line2 {
  display: inline-block;
  margin-left: 10px;
  text-decoration: underline;
  color: dodgerblue;
  cursor: pointer;
}

.formDiv {
  position: relative;
  border-radius: 0.75rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 1rem;
  max-width: 100%;
  width: 450px;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  color: #333333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.recoverDiv {
  position: relative;
  border-radius: 0.75rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 1rem;
  max-width: 100%;
  width: 490px;
  height: inherit;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.vectorIcon {
  position: relative;
  width: 20px;
  height: 17px;
  cursor: pointer;
}

.labelDiv {
  border-radius: 0.375rem;
  height: 2.5rem;
  padding-left: 1rem;
  border: 2px solid #a19b9b;
}

.buttonDiv {
  border-radius: 0.375rem;
  height: 35px;
  font-weight: 600;
  background: #fbc335;
  color: black;
  border-radius: 10px;
  border: none;
}


.buttonDiv:hover {
  background: #b88e25;
  color: white;
  transform: scale(1.005);
}

.buttonDivDisabled {
  border-radius: 0.375rem;
  height: 35px;
  background: #555451;
  color: #a29f9f;
}

.containerCard {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 4;
}
.firstRowIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.connectionCard {
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  align-items: baseline;
  background: whitesmoke;
  height: 320px;
  width: 550px;
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 10px;
  align-items: center;
  border-radius: 15px;
  animation: fadeIn 1s;
  color: black;
  animation-fill-mode: forwards;
}

.connectionCard.fadeOut {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  z-index: 0;
}

.flexDiv {
  align-self: center;
  display: flex;
  gap: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input {
  padding: 6px 12px;
  font-size: 16px;
  width: 250px;
  border-radius: 10px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.rowDiv {
  display: flex;
  flex-direction: row;
  width: inherit;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.rowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  gap: 25%;
  justify-content: flex-end;
  width: inherit;
}
.rowConnection h1 {
  font-size: 20px;
  color: #c8b568;
  font-weight: 900;
}

.columnConnection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 20px 5px;
}

.columnConnection h1 {
  font-size: 15px;
  color: #1f4781;
  font-weight: 900;
  margin: 0;
}

.columnConnection p {
  font-size: 12px;
  margin-bottom: 5px;
}

.submitButton {
  width: fit-content;
  margin-top: 10px;
  padding: 8px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton2 {
  width: fit-content;
  margin-top: 10px;
  padding: 8px 20px;
  font-size: 14px;
  border: grey 1px solid;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton2:hover {
  background-color: grey;
  color: black;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.submitButton:hover {
  background-color: #0056b3;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
